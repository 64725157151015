import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import { store } from './store'
import vuetify from './plugins/vuetify';
store.dispatch('loginWithToken').catch((e)=>{
  console.error(e)
  store.dispatch('logout')
}).finally(()=>{
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
})