var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[_c('v-app-bar',{staticClass:"d-flex justify-center no-print pa-0",attrs:{"fixed":"","color":_vm.$vuetify.theme.themes[_vm.theme].secondary,"max-height":"55"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('img',{staticClass:"mr-2",attrs:{"src":"/logo.png","height":"25"}}),_vm._l((_vm.headerItems),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.scopes.includes(_vm.client.scope)),expression:"item.scopes.includes(client.scope)"}],key:index},[_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"active-class":"active-btn","text":"","to":item.link}},[_c('span',{staticClass:"menuHeaders"},[_vm._v(_vm._s(item.title))])])],1)}),_c('div',{staticClass:"align-self-center"},[_c('img',{staticClass:"company-logo",attrs:{"src":_vm.$store.state.settings.company.logo,"alt":"logo"}})])],2),_c('v-main',{staticClass:"mt-9 pa-5"},[_c('v-container',{class:{ rounded: true, 'no-background': !_vm.loggedIn },style:({
        background: _vm.$vuetify.theme.themes[_vm.theme].container_background,
        'min-height': '100vh',
      }),attrs:{"fluid":""}},[(!_vm.dataLoaded)?_c('div',{staticClass:"progressBar"},[_c('v-progress-linear',{attrs:{"rounded":"","height":"75px"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('img',{attrs:{"src":_vm.$store.state.settings.loadingImage,"alt":""}})])],1):_vm._e(),_c('router-view')],1)],1),(_vm.snack.snackBar)?_c('div',{staticClass:"snack-bar-container"},[_c('div',{class:{
        'snack-bar': true,
        success: _vm.snack.success,
        error: _vm.snack.error,
        info: _vm.snack.info,
      }},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v(" "+_vm._s(_vm.snack.snackText)+" ")]),(_vm.snack.action)?_c('div',[_c('v-btn',{attrs:{"text":"","color":"info"},on:{"click":_vm.snack.action}},[_vm._v(_vm._s(_vm.snack.actionText))])],1):_vm._e(),_c('div',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$store.commit('snack', {
                snackBar: false,
                snackText: '',
              })}}},[_vm._v("Sluit")])],1)])])]):_vm._e(),_c('v-navigation-drawer',{staticStyle:{"will-change":"initial"},attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.menuItems),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.scopes.includes(_vm.client.scope)),expression:"item.scopes.includes(client.scope)"}],key:index},[(!item.items)?_c('v-list-item',{attrs:{"to":item.link,"active-class":"activeClass"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1):_c('v-list-group',{attrs:{"prepend-icon":item.icon,"no-action":"","active-class":"activeClass"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.items),function(child){return _c('v-list-item',{key:child.title,attrs:{"to":child.link,"active-class":"activeClass"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(child.title))])],1)}),1)],1)}),_c('v-list-item',{on:{"click":function($event){return _vm.toggleTheme()}}},[(_vm.$vuetify.theme.dark)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lightbulb")])],1):_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lightbulb")])],1),_c('v-list-item-title',[_vm._v(" Donkere modus ")])],1),(_vm.loggedIn)?_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Uitloggen")])],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }