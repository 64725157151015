<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-app-bar
      fixed
      :color="$vuetify.theme.themes[theme].secondary"
      max-height="55"
      class="d-flex justify-center no-print pa-0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img class="mr-2" src="/logo.png" height="25" />
      <div
        v-show="item.scopes.includes(client.scope)"
        v-for="(item, index) in headerItems"
        :key="index"
      >
        <v-btn
          class="d-none d-md-flex"
          active-class="active-btn"
          text
          :to="item.link"
        >
          <span class="menuHeaders">{{ item.title }}</span>
        </v-btn>
      </div>
      <div class="align-self-center">
        <img
          :src="$store.state.settings.company.logo"
          alt="logo"
          class="company-logo"
        />
      </div>
    </v-app-bar>
    <v-main class="mt-9 pa-5">
      <v-container
        fluid
        :class="{ rounded: true, 'no-background': !loggedIn }"
        :style="{
          background: $vuetify.theme.themes[theme].container_background,
          'min-height': '100vh',
        }"
      >
        <div v-if="!dataLoaded" class="progressBar">
          <v-progress-linear rounded height="75px" v-model="progress"
            ><img :src="$store.state.settings.loadingImage" alt=""
          /></v-progress-linear>
        </div>
        <router-view />
      </v-container>
    </v-main>
    <div class="snack-bar-container" v-if="snack.snackBar">
      <div
        :class="{
          'snack-bar': true,
          success: snack.success,
          error: snack.error,
          info: snack.info,
        }"
      >
        <div class="d-flex justify-space-between">
          <div class="d-flex justify-center align-center">
            {{ snack.snackText }}
          </div>
          <div v-if="snack.action">
            <v-btn text color="info" @click="snack.action">{{
              snack.actionText
            }}</v-btn>
          </div>
          <div>
            <v-btn
              text
              color="primary"
              @click="
                $store.commit('snack', {
                  snackBar: false,
                  snackText: '',
                })
              "
              >Sluit</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <v-navigation-drawer
      style="will-change: initial"
      app
      temporary
      v-model="drawer"
    >
      <v-list nav dense>
        <div
          v-show="item.scopes.includes(client.scope)"
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <v-list-item
            v-if="!item.items"
            :to="item.link"
            active-class="activeClass"
          >
            <v-list-item-icon
              ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
            active-class="activeClass"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              active-class="activeClass"
            >
              <v-list-item-icon
                ><v-icon>{{ child.icon }}</v-icon></v-list-item-icon
              >
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
        <v-list-item @click="toggleTheme()">
          <v-list-item-icon v-if="$vuetify.theme.dark"
            ><v-icon>mdi-lightbulb</v-icon></v-list-item-icon
          >
          <v-list-item-icon v-else
            ><v-icon>mdi-lightbulb</v-icon></v-list-item-icon
          ><v-list-item-title> Donkere modus </v-list-item-title></v-list-item
        >
        <v-list-item v-if="loggedIn" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Uitloggen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>
<script>
import { errorHandler, successHandler } from "./js/functions"
export default {
  name: "App",
  data: () => ({
    drawer: false,
    inProgress: false,
    headerItems: [
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/", scopes: [2, 3] },
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/myDashboard", scopes: [1] },
      { title: "Offertes", icon: "mdi-file-clock", link: "/quotes", scopes: [2, 3] },
      { title: "Orders", icon: "mdi-file-document", link: "/orders", scopes: [2, 3] },
      { title: "Orders", icon: "mdi-file-document", link: "/myOrders", scopes: [1] },
      { title: "Facturen", icon: "mdi-file-check", link: "/invoices", scopes: [2, 3] },
      { title: "Facturen", icon: "mdi-file-check", link: "/myInvoices", scopes: [1] },
      {
        title: "Nieuwe order",
        icon: "mdi-file-document-edit",
        link: "/newOrder", scopes: [2, 3]
      },
      {
        title: "Nieuwe order",
        icon: "mdi-file-document-edit",
        link: "/myNewOrder", scopes: [1]
      },
      { title: "Artikelen", icon: "mdi-cube-outline", link: "/items", scopes: [2, 3] },
      { title: "Klanten", icon: "mdi-account", link: "/clients", scopes: [2, 3] }
    ],
    menuItems: [
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/", scopes: [2, 3] },
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/myDashboard", scopes: [1] },
      { title: "Orders", icon: "mdi-file-document", link: "/myOrders", scopes: [1] },
      { title: "Facturen", icon: "mdi-file-check", link: "/myInvoices", scopes: [1] },
      {
        title: "Nieuwe order",
        icon: "mdi-file-document-edit",
        link: "/myNewOrder", scopes: [1]
      },

      {
        title: "Verkoop",
        icon: "mdi-cash-register",
        link: "/",
        items: [
          { title: "Offertes", icon: "mdi-file-clock", link: "/quotes" },
          { title: "Orders", icon: "mdi-file-document", link: "/orders" },
          { title: "Facturen", icon: "mdi-file-check", link: "/invoices" },
          {
            title: "Nieuwe order",
            icon: "mdi-file-document-edit",
            link: "/newOrder"
          },

          { title: "Omzet", icon: "mdi-trending-up", link: "/revenue" },
        ], scopes: [2, 3]
      },
      {
        title: "Inkoop",
        icon: "mdi-cart-arrow-down",
        link: "/",
        items: [
          {
            title: "Inkoopartikelen",
            icon: "mdi-cube-outline",
            link: "/purchaseItems"
          },
          {
            title: "Inkooporders",
            icon: "mdi-file-download",
            link: "/purchaseOrders"
          },
          {
            title: "Inkoopfacturen",
            icon: "mdi-file-download",
            link: "/purchaseInvoices"
          },
          {
            title: "Nieuwe inkooporder",
            icon: "mdi-file-document-edit",
            link: "/newPurchaseOrder"
          },
          {
            title: "Krattensaldo",
            icon: "mdi-swap-vertical-bold",
            link: "/cratesBalance"
          },
          {
            title: "Leveranciers",
            icon: "mdi-truck-delivery",
            link: "/suppliers"
          },
        ], scopes: [2, 3]
      },
      {
        title: "Artikelen",
        icon: "mdi-cube-outline",
        link: "/",
        items: [
          { title: "Alle artikelen", icon: "mdi-cube-outline", link: "/items" },
          { title: "Artikelhistorie", icon: "mdi-archive-clock", link: "/itemsSalesHistory" },
          { title: "Artikelomzet", icon: "mdi-trending-up", link: "/itemsRevenue" },
          { title: "Artikelomzet per klant", icon: "mdi-trending-up", link: "/itemsRevenueByClient" },
          { title: "Prijsaanpassingen", icon: "mdi-cube-outline", link: "/priceAdjustments" },
          { title: "Traceability", icon: "mdi-label", link: "/traceability" },
          { title: "Voorraadbeheer", icon: "mdi-warehouse", link: "/stock" },
        ], scopes: [2, 3]
      },
      {
        title: "Klanten",
        icon: "mdi-account",
        link: "/",
        items: [
          { title: "Alle klanten", icon: "mdi-account", link: "/clients" },
          {
            title: "Openstaande debiteuren",
            icon: "mdi-receipt",
            link: "/outstandingDebtors"
          },
          {
            title: "Laatste order",
            icon: "mdi-account-clock",
            link: "/lastOrderDate"
          },
          {
            title: "Krattensaldo",
            icon: "mdi-swap-vertical-bold",
            link: "/clientsCratesBalance"
          },
        ], scopes: [2, 3]
      },
      {
        title: "Logistiek",
        icon: "mdi-truck-fast",
        link: "/",
        items: [
          { title: "Sorteer totalen", icon: "mdi-sort", link: "/sortItems" },
          { title: "Voertuigen", icon: "mdi-truck", link: "/vehicles" },
          { title: "Chauffeurs", icon: "mdi-steering", link: "/drivers" }
        ], scopes: [2, 3]
      },
      {
        title: "Personeel",
        icon: "mdi-account-group",
        link: "/",
        items: [
          { title: "Chauffeurs", icon: "mdi-steering", link: "/drivers" },
          { title: "Werknemers", icon: "mdi-account-group", link: "/employees" },
          {
            title: "Urenregistratie",
            icon: "mdi-timetable",
            link: "/timesheets"
          },
        ], scopes: [2, 3]
      },
      {
        title: "Kasboek",
        icon: "mdi-cash",
        link: "/ledger",
        scopes: [2, 3]
      },
      {
        title: "Bank",
        icon: "mdi-bank",
        link: "/",
        items: [
          {
            title: "Bankafschrift importeren",
            icon: "mdi-application-import",
            link: "/camt053Parser"
          }
        ], scopes: [2, 3]
      },
      {
        title: "Boekhouding",
        icon: "mdi-book-open-page-variant",
        link: "/",
        items: [
          {
            title: "ICP",
            icon: "mdi-airplane-takeoff",
            link: "/icp"
          },
          {
            title: "Verkoop GB",
            icon: "mdi-truck",
            link: "/salesGB"
          },
          {
            title: "Verkoop buiten EU",
            icon: "mdi-truck",
            link: "/salesOutsideEU"
          },
          {
            title: "Omzetbelasting",
            icon: "mdi-currency-eur",
            link: "/VATDeclaration"
          },
          { title: "Omzet", icon: "mdi-trending-up", link: "/revenue" },
          {
            title: "Openstaande debiteuren",
            icon: "mdi-receipt",
            link: "/outstandingDebtors"
          },
        ], scopes: [2, 3]
      },
      { title: "Instellingen", icon: "mdi-cog", link: "/settings", scopes: [2, 3] }
    ],
    right: null
  }),
  methods: {
    getPrinters() {
      console.log("getPrinters")
      window.ipcRenderer.send("getPrinterList", null)
      window.ipcRenderer.receive("getPrinterList", data => {
        this.$store.commit("setPrinters", data)
      })
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      let val = this.$vuetify.theme.dark ? "dark" : "light"
      this.$store.commit("setTheme", val)
      localStorage.setItem("theme", val)
    },
    async logout() {
      try {
        await this.$store.dispatch("logout")
        successHandler("U bent afgemeld", 3000)
      } catch (e) {
        errorHandler(e, "Fout bij afmelden")
      }
    }
  },
  computed: {
    snack() {
      return this.$store.state.snack
    },
    loggedIn() {
      return this.$store.state.loggedIn
    },
    progress() {
      return this.$store.state.progress
    },
    dataLoaded() {
      return this.$store.state.dataLoaded
    },
    theme() {
      return this.$store.state.theme
    },
    client() {
      return this.$store.state.client
    },
  },
  beforeCreate() {
    let theme = localStorage.getItem("theme")
    if (theme) {
      this.$store.commit("setTheme", localStorage.getItem("theme"))
      this.$vuetify.theme.dark = theme === "dark" ? true : false
    }
    else this.$store.commit("setTheme", "light")
  },
  mounted() {
    var userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(" electron/") > -1) {
      this.$store.commit("isElectron", true)
      this.getPrinters()
    }
  }
}
</script>
<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.menuHeaders {
  color: white;
  font-size: 1em;
}
.progressBar {
  z-index: 999;
  position: fixed;
  width: 500px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -250px;
}
.activeClass {
  background: #69a9e4 !important;
}
.snack-bar-container {
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.snack-bar {
  justify-content: center;
  width: 350px;
  padding: 20px;
  color: #ffffff;
  font-size: 1.2rem;
  border-radius: 10px;
  pointer-events: auto;
}
.success {
  background-color: #4caf50;
}
.error {
  background-color: #f44336;
}
.info {
  background-color: #2569b6;
}
/* .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 0 !important;
} */
.company-logo {
  max-width: 100px;
  max-height: 55px;
}
.no-background {
  background: none !important;
}
.active-btn {
  background: #69a9e4 !important;
}
</style>