import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import nl from 'vuetify/es5/locale/nl'

Vue.use(Vuetify)


export default new Vuetify({
  theme:{
    dark: false,
    options:{
      customProperties:true
    },
    themes: {
      light: {
        primary: '#3066BE',
        secondary: '#3066BE',
        accent: '#69a9e4',
        anchor: '#000000',
        error: '#b71c1c',
        background: '#B6B8D6',
        container_background: '#EFE9F4',
      },
      dark: {
        primary: '#399DE0',
        secondary: '#1e1e1e',
        accent: '#399DE0',
        anchor: '#ffffff',
        error: '#b71c1c',
        background: '#333333',
        container_background: '#747C92',
      }
    },
  },
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { nl},
    current: 'nl',
  },
})